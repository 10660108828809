//document.addEventListener('turbolinks:load', () => {
  console.log('top/index');

  // Watchword(トップ画面の文字列のアニメーション)
  const CLASSNAME = "-visible";
  const TIMEOUT = 3000;
  const DELAY = 1000;
  const target1 = document.querySelector(".title");
  const target2 = document.querySelector(".sentence");

  setInterval(() => {
    target1.classList.add(CLASSNAME);
    setTimeout(() => {
      target2.classList.add(CLASSNAME);
    }, DELAY);

    setTimeout(() => {
      target1.classList.remove(CLASSNAME);
      setTimeout(() => {
        target2.classList.remove(CLASSNAME);
      }, DELAY);
    }, TIMEOUT);
  }, TIMEOUT * 2);
 

  // Swiper関連
  const swiper_photos = new Swiper('.photos-container', {
    speed: 600,
    parallax: true,
    loop: true,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
  })

  const swiper_link_contents = new Swiper('.link-contents-container', {
    speed: 600,
    //parallax: true,
    loop: true,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.link-contents-container-swiper-button-next',
      prevEl: '.link-contents-container-swiper-button-prev',
    }
  })

  const swiper_local_foods = new Swiper('.local-foods-container', {
    slidesPerView: 1.2,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 10,
        navigation: {
          nextEl: '.local-foods-container-swiper-button-next',
          prevEl: '.local-foods-container-swiper-button-prev'
        }
      }
    }
  })

  const swiper_popular_areas = new Swiper('.popular-areas-container', {
    slidesPerView: 1.2,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2.2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: {
          nextEl: '.popular-areas-container-swiper-button-next',
          prevEl: '.popular-areas-container-swiper-button-prev'
        }
      }
    }
  })
  // Swiper関連終了


  const anchorLinks = document.querySelectorAll('a[href^="#band"]')
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  anchorLinksArr.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      const totalScrollAmount = targetOffsetTop;
      window.scrollTo({
        top: totalScrollAmount,
        behavior: "smooth"
      });
    });
  });

//});



/////////////////////////////////////////////////////////////////////////////////////////

    var isXXLarge, isXLarge, isLarge, isMedium, isSmall;
    isSmall = function() {
      return Foundation.MediaQuery._getCurrentSize() === "small";
    };

    isMedium = function() {
      return Foundation.MediaQuery._getCurrentSize() === "medium";
    };

    isLarge = function() {
      return Foundation.MediaQuery._getCurrentSize() === "large";
    };

    isXLarge = function() {
      return Foundation.MediaQuery._getCurrentSize() === "xlarge";
    };

    isXXLarge = function() {
      return Foundation.MediaQuery._getCurrentSize() === "xxlarge";
    };

    window.clickHere = () => {
      const checkbox_here = document.getElementById("search_distance_id");
      const select_planning_area = document.getElementById("search_planning_area_id");

      if (checkbox_here.checked == true) {
        select_planning_area.disabled = true;
        document.getElementById("search_type").value = "here";

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {


            const polArray = [
              {lat:1.4812024,lng:103.5831503},
              {lat:1.4911034,lng:104.0906183},
              {lat:1.1591064,lng:104.1346933},
              {lat:1.1444374,lng:1.1444374}
            ]
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const latlng = new google.maps.LatLng(lat, lng);
            const polygonObj = new google.maps.Polygon({paths: polArray});
            const res = google.maps.geometry.poly.containsLocation(latlng, polygonObj);
            if(res){
              document.getElementById("lat").value = lat;
              document.getElementById("lng").value = lng;
            }else{
              // Chinatown Complex Market
              document.getElementById("lat").value = 1.282276;
              document.getElementById("lng").value = 103.843193;
            }

          }
          ,(err) =>{
            alert(`could not get position. ERROR(${err.code}): ${err.message}`);
          });
        } else {
          alert("geolocation IS NOT available.");
        }

      } else {
        select_planning_area.disabled = false;

        //target.innerHTML = "";
        const index = document.querySelector("#search_planning_area_id").selectedIndex;

        if (index == 0) {
          document.querySelector("#search_type").value = "";
        } else {
          document.querySelector("#search_type").value = "area";
        }
        document.querySelector("#lat").value = "";
        document.querySelector("#lng").value = "";
      }
    }


    window.onSelectWordClick = (select_keyword) => {
      const elements = document.getElementsByName("search[keyword]");
      elements[0].value = select_keyword;
      elements[1].value = select_keyword;
    }


    window.changeArea = () => {
      const index = document.querySelector("#search_planning_area_id").selectedIndex;
      if (index == 0) {
        document.querySelector("#search_type").value = "";
      } else {
        document.querySelector("#search_type").value = "area";
      }
    }


    window.serachHere = (form, lat_id, lng_id) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {

          const polArray = [
            {lat:1.4812024,lng:103.5831503},
            {lat:1.4911034,lng:104.0906183},
            {lat:1.1591064,lng:104.1346933},
            {lat:1.1444374,lng:1.1444374}
          ]

          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const latlng = new google.maps.LatLng(lat, lng);

          const polygonObj = new google.maps.Polygon({paths: polArray});
          const res = google.maps.geometry.poly.containsLocation(latlng, polygonObj);
          if(res){
            document.getElementById(lat_id).value = lat;
            document.getElementById(lng_id).value = lng;
          }else{
            // Chinatown Complex Market
            document.getElementById(lat_id).value = 1.282276;
            document.getElementById(lng_id).value = 103.843193;
          }
          form.submit();
        }
        , function(err){
          alert(`could not get position. ERROR(${err.code}): ${err.message}`);
        });
      } else {
        alert("geolocation IS NOT available.");
      }
    }